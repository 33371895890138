import { render, staticRenderFns } from "./CreatePricingZoneRelation.vue?vue&type=template&id=f0f3a378&scoped=true"
import script from "./CreatePricingZoneRelation.vue?vue&type=script&lang=js"
export * from "./CreatePricingZoneRelation.vue?vue&type=script&lang=js"
import style0 from "./CreatePricingZoneRelation.vue?vue&type=style&index=0&id=f0f3a378&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0f3a378",
  null
  
)

export default component.exports